import request from "graphql-request";
import React, { useEffect, useState } from "react";
import Loading from "../components/loading";
import Technology from "../components/technology";

function RubyPage({ pageContext }) {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});

  const strapiHost = process.env.GATSBY_API_URL;
  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <>:)</>
        // <Technology
        //   hero={{
        //     header: data.title,
        //     description: data.description,
        //     image: `${strapiHost}${data.img.url}`,
        //   }}
        //   why={{
        //     title: data.title2,
        //     answers: data.technology_single1.map((item) => ({
        //       title: item.title,
        //       description: item.description,
        //     })),
        //   }}
        //   hero2={{
        //     header: data.title3,
        //     description: data.description2,
        //     image: `${strapiHost}${data.img2.url}`,
        //   }}
        // />
      )}
    </>
  );
}

export default RubyPage;
